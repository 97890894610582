import React from 'react'
import Layout from '../components/Layout'
import BlockquoteHero from "../components/pagebuilderComponents/BlockquoteHero/BlockquoteHero";
import TextAndPhotoMasthead from "../components/pagebuilderComponents/TextAndPhotoMasthead/TextAndPhotoMasthead";
import ExperientialHero from "../components/ExperientialIntro/ExperientialHero";

const PageTemplate = () => {
  const textAndPhotoMastheadData = {
    primaryHeading: 'Styleguide',
    textContent: 'This page includes examples of different components for reference purposes.'
  }

  const blockquoteHeroData1 = {
    theme: 'Thermal'
  }

  const blockquoteHeroData2 = {
    theme: 'Cloud'
  }

  const blockquoteHeroData3 = {
    theme: 'Mineral'
  }

  return (
    <Layout>
      <ExperientialHero/>

      <TextAndPhotoMasthead data={textAndPhotoMastheadData}/>

      <BlockquoteHero data={blockquoteHeroData1}/>
      <BlockquoteHero data={blockquoteHeroData2}/>
      <BlockquoteHero data={blockquoteHeroData3}/>
    </Layout>
  )
}

export default PageTemplate
